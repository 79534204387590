<template>
  <div v-for="{ id, type, status, carrier, tracking_number, vendor } in patientRPMDevices.data" :key="id">
    <ion-card class="device-card">
      <ion-card-header>
        <ion-card-subtitle class="ion-text-capitalize device-name">
          {{ presentDeviceType(type, vendor) }}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content class="device-status">
        <ion-icon :ios="deviceStatus(status, 'icon')" :md="deviceStatus(status, 'icon')"
            :color="deviceStatus(status, 'color')"/>
        {{ presentDeviceStatus(status) }}
        <span v-if="status === 'shipped' && (carrier || tracking_number)">
          &nbsp;-
          <a v-if="carrier && tracking_number" :href="getTrackingLink(carrier, tracking_number)"
              target="_blank" rel="noopener noreferrer">
            Track
          </a>
          <span v-else>
            {{carrier}}{{tracking_number}}
          </span>
        </span>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
} from '@ionic/vue'
import {
  checkmarkCircle,
  airplane,
  cart,
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'DeviceList',
  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
  },
  methods: {
    deviceStatus (status, type) {
      switch (status) {
        case 'active':
          return type === 'color' ? 'success' : checkmarkCircle
        case 'shipped':
          return type === 'color' ? 'primary' : airplane
        default:
          return type === 'color' ? 'warning' : cart
      }
    },
    presentDeviceType (type, vendor) {
      return vendor === 'dexcom' ? 'Dexcom CGM' : `${type.replace('_', ' ')} Monitor`
    },
    presentDeviceStatus (status) {
      switch (status) {
        case 'active':
          return 'Active'
        case 'shipped':
          return 'Shipped'
        default:
          return 'Awaiting shipment'
      }
    },
    getTrackingLink (carrier, trackingNumber) {
      switch (carrier) {
        case 'USPS':
          return `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`
        default:
          return `https://www.google.com/search?q=${trackingNumber}`
      }
    },
  },
  computed: mapGetters([
    'patientRPMDevices',
  ]),
})
</script>

<style scoped>
.device-name {
  font-size: 1rem;
}
.device-status {
  display: flex;
  align-items: center;
}
.device-status a {
  text-decoration: none;
  font-weight: 600;
}
.device-status ion-icon {
  margin-right: 0.25em;
}
.device-card ion-card-header {
  padding: 15px 20px 10px 20px;
}
.device-card ion-card-content {
  padding: 0 20px 10px 20px;
}
</style>
