<template>
  <ion-button @click="openModal">
    Status
  </ion-button>
  <ion-modal :is-open="showModal" @didDismiss="showModal=false">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button @click="closeModal" default-href='/monitor'/>
          </ion-buttons>
          <ion-title>
            Status
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-text-start">
        <DeviceList/>
      </ion-content>
    </ion-page>
  </ion-modal>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonModal,
  IonPage,
  IonBackButton,
} from '@ionic/vue'
import { defineComponent } from 'vue'
import DeviceList from './DeviceList'

export default defineComponent({
  name: 'DeviceListModal',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonButtons,
    IonModal,
    IonPage,
    IonBackButton,
    DeviceList,
  },
  data () {
    return {
      showModal: false,
    }
  },
  methods: {
    openModal () {
      let modal = document.getElementsByTagName('ion-modal')[0]
      if (this.showModal && modal) {
        modal.dismiss().then(() => {
          this.showModal = true
        })
      } else {
        this.showModal = true
      }
    },
    closeModal () {
      let modal = document.getElementsByTagName('ion-modal')[0]
      if (this.showModal && modal) {
        modal.dismiss()
      }
    },
  },
})
</script>
