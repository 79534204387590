<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>
          Monitor
        </ion-title>
        <ion-buttons slot="end" v-if="!loading && devicesAvailable && hasActiveDevice">
          <DeviceListModal/>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-loading :is-open="loading" message="Loading..." :duration="20000" cssClass="medium-loading-container"
          @didDismiss="loading=false"/>
      <div v-if="!loading">
        <div v-if="devicesAvailable">
          <div v-if="hasActiveDevice">
            <div v-for="vitalType in vitalTypes" :key="vitalType">
              <VitalList v-if="vitalTypeAvailable(vitalType)" :vitalType="vitalType"/>
            </div>
          </div>
          <div v-else>
            <ion-card>
              <ion-card-content class="monitor-info-card">
                <div class="monitor-description">
                  When you start using your monitor, we will show the results here.
                </div>
                <div>
                  In the meantime, you can check the shipment status below.
                </div>
              </ion-card-content>
            </ion-card>
            <DeviceList/>
          </div>
        </div>
        <ion-card v-else>
          <ion-card-content class="ion-text-start">
            <div class="monitor-description">
              You have no monitors yet.
            </div>
            <div>
              Please
              <a style="text-decoration:none" @click="$router.push('/messages')">
                contact your care manager
              </a>
              for more information.
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLoading,
  IonCard,
  IonCardContent,
  IonButtons,
} from '@ionic/vue'
import { mapGetters } from 'vuex'
import {
  PATIENT_RPM_DEVICES_CHECK, PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST, PATIENT_RPM_VITALS_REQUEST
} from '../store/actions/patient'
import DeviceList from '../components/DeviceList'
import DeviceListModal from '../components/DeviceListModal'
import VitalList from '../components/VitalList'
import {
  bloodGlucoseClinicalIDs,
  bloodPressureClinicalIDs,
  o2SaturationClinicalIDs,
  heartRateClinicalIDs,
  weightClinicalIDs,
} from '../utils/vitals'
import { dismissElement } from '../utils/overlay'

export default {
  name: 'Monitor',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonCard,
    IonCardContent,
    IonButtons,
    DeviceList,
    DeviceListModal,
    VitalList,
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'patientRPMVitals',
      'patientRPMDevices',
    ]),
    devicesAvailable () {
      return !this.loading && this.patientRPMDevices.hasLoadedOnce && this.patientRPMDevices.data.length
    },
    hasActiveDevice () {
      return this.patientRPMDevices.data.some(({status}) => status === 'active')
    },
  },
  data () {
    return {
      loading: false,
      devicesModal: null,
      vitalTypes: [
        {
          path: 'blood_glucose',
          value: 'bloodGlucose',
          label: 'Blood Glucose',
          clinicalIDs: bloodGlucoseClinicalIDs,
          deviceType: 'glucose',
        },
        {
          path: 'blood_pressure',
          value: 'bloodPressure',
          label: 'Blood Pressure',
          clinicalIDs: bloodPressureClinicalIDs,
          deviceType: 'blood_pressure',
        },
        {
          path: 'o2_saturation',
          value: 'o2Saturation',
          label: 'Blood Oxygen',
          clinicalIDs: o2SaturationClinicalIDs,
          deviceType: 'pulse_oximeter',
        },
        {
          path: 'heart_rate',
          value: 'heartRate',
          label: 'Heart Rate',
          clinicalIDs: heartRateClinicalIDs,
          deviceType: 'blood_pressure',
        },
        {
          path: 'weight',
          value: 'weight',
          label: 'Weight',
          clinicalIDs: weightClinicalIDs,
          deviceType: 'weight',
        },
      ],
    }
  },
  methods : {
    vitalTypeAvailable ({deviceType}) {
      return this.patientRPMDevices.data.some(({type, status}) => type === deviceType && status === 'active')
    },
  },
  ionViewWillEnter () {
    if (this.devicesAvailable && this.hasActiveDevice) {return}
    dismissElement('ion-loading')
    this.loading = true
    Promise.all([
      this.$store.dispatch(PATIENT_RPM_DEVICES_CHECK),
      this.$store.dispatch(PATIENT_RPM_VITALS_REQUEST, {params: {mobile: true}}),
      this.$store.dispatch(PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST, {params: {mobile: true}})
    ]).then(() => {
      this.loading = false
    })
  },
}
</script>

<style scoped>
ion-item {
  --border-style: none;
}
ion-card-content {
  text-align: center;
}
.monitor-description {
  margin-bottom: 1em;
}
.monitor-info-card {
  font-size: 0.85rem !important;
  text-align: left;
}
</style>
